import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { format } from "date-fns";

import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import { ContentBox } from "components/ContentBox";
import { ContentLeft } from "templates/ContentLeft";
import { ButtonSVG } from "../../../components/ButtonSVG";
import { ShowWhenHavePermission } from "components/Permission";
import { RenderLocationAddress } from "components/RedirectLocationAddress";
import { UserExecutionCard } from "components/UserExecutionCard";
import { ModalRight } from "components/ModalRight";
import { ModalObservationDetail } from "components/ModalObservationDetail";
import { CardChecklistDetail } from "components/CardChecklistDetail";

import CheckSvg from "assets/icons/check.svg";

import { useToast } from "hooks/toast";
import { useServiceOrder } from "hooks/serviceOrder";
import { useAccount } from "hooks/permission/account";

import apiv2 from "services/apiv2";
import { api } from "services/api";

import { ClientProps, UnityDTO } from "dtos/ClientDTO";
import { ServiceDTO } from "../../../dtos/ServiceDTO";
import { RegisterProps } from "dtos/ServiceChecklistDTO";

import * as S from "./styles";

type RouteParams = {
  id: string;
};

type UnitiesResponse = {
  unities: UnityDTO[];
};

type ServicesParam = {
  idBudgetService: number;
  quantity: number;
  scheduleDate: string;
  formatedDate?: string;
  status: string;
} & ServiceDTO;

type CardChecklistDetailProps = {
  nameCheckList: string;
  checklistRegisters: RegisterProps[];
};

export function ServiceOrderPendingDetail() {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const { selectedServiceOrder, handleSetSelectedServiceOrder } =
    useServiceOrder();

  const [unityClient, setUnityClient] = useState({} as UnityDTO);

  const [isLoading, setIsLoading] = useState(true);

  const [canSchedule, setCanSchedule] = useState(false);

  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState("");

  const [isOpenCheckListDetail, setIsOpenCheckListDetail] =
    useState<CardChecklistDetailProps>({} as CardChecklistDetailProps);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (clientResponse?.address && clientResponse?.address?.id) {
        clientResponse.address &&
          setUnityClient(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      defaultUnity && setUnityClient(defaultUnity);
    } catch {
      addToast({
        title: "Opss",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }

  async function getServiceOrderDetail() {
    try {
      const response = await apiv2.get(`budgets/service-order/${id}`, {
        params: {
          accountId: whoami?.id,
        },
      });

      handleSetSelectedServiceOrder(response.data);

      loadUnityData(response.data.client);

      const filteredData = response.data.services.filter(
        (item: ServicesParam) => {
          return item.status !== "concluded" && item.status !== "scheduled";
        }
      );

      const formatedResponse = {
        ...response.data,
        services: filteredData.map((currentService: ServicesParam) => {
          const { idBudgetService } = currentService;
          return {
            ...currentService.service,
            idBudgetService,
          };
        }),
      };

      if (formatedResponse.services.length > 0) {
        setCanSchedule(true);
      }
    } catch (err) {
      addToast({
        title: "Ops...",
        description: "Erro ao buscar detalhes da ordem de serviço",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getServiceOrderDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleRedirect() {
    history.push(`/scheduling/service/${id}`);
  }

  async function handleArchiveServiceOrder() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/service-order/${id}/archive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "Ordem de serviço arquivada",
        description: "",
      });

      history.push({
        pathname: "/service-order",
        state: { before: "ServiceOrderPendingDetail" },
      });
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao arquivar ordem de serviço",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: selectedServiceOrder.detailLink,
      },
    });
  }

  function handleStartServiceOrder() {
    history.push(`/service-photos/${id}`);
  }

  function handleToggle(observation: string) {
    setIsOpenObservationDetail(observation);
  }

  function handleToggleModalCheckListDetail({
    nameCheckList,
    checklistRegisters,
  }: CardChecklistDetailProps) {
    setIsOpenCheckListDetail({ nameCheckList, checklistRegisters });
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={!!isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => handleToggle("")}
      >
        <ModalObservationDetail
          observation={isOpenObservationDetail || ""}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() => handleToggle("")}
        />
      </ModalRight>

      <ModalRight
        isOpen={!!isOpenCheckListDetail?.nameCheckList}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() =>
          handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
        }
      >
        <CardChecklistDetail
          nameCheckList={isOpenCheckListDetail?.nameCheckList}
          checklistRegisters={isOpenCheckListDetail?.checklistRegisters}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() =>
            handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
          }
        />
      </ModalRight>

      {!isLoading && (
        <ContentLeft>
          <header>
            <div>
              <ArrowButton
                handleFunction={() => {
                  history.push("/service-order", {
                    before: "",
                  });
                }}
              />
            </div>

            <h1>Ordem de serviço {selectedServiceOrder.sequenceNumber} </h1>
          </header>

          <S.WrapperDate>
            <span>
              Data de Criação:{" "}
              {format(new Date(selectedServiceOrder.createdAt), "dd/MM/yyyy")}
            </span>

            <div>
              <div />
              <span>Em andamento</span>
            </div>
          </S.WrapperDate>

          <S.Content>
            <S.Wrapper>
              <ContentBox title="Cliente">
                <S.ContentBoxTitle>
                  {selectedServiceOrder.client.name}
                </S.ContentBoxTitle>
                <S.ContentBoxText>
                  {selectedServiceOrder.client?.cpf_cnpj}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {selectedServiceOrder.client?.phone}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {selectedServiceOrder.client?.email}
                </S.ContentBoxText>
              </ContentBox>
            </S.Wrapper>

            {unityClient &&
              (!unityClient.id ||
              Object.keys(unityClient).length < 1 ||
              !unityClient.postalCode ? (
                <S.Wrapper>
                  <ContentBox title="Endereço">
                    <S.ContentBoxText>
                      Sem endereço cadastrado.
                    </S.ContentBoxText>
                  </ContentBox>
                </S.Wrapper>
              ) : (
                <S.Wrapper>
                  <ContentBox title="Endereço">
                    <S.ContentBoxTitle>
                      {unityClient.name}{" "}
                      {unityClient.default ? "(principal)" : "(adicional)"}
                    </S.ContentBoxTitle>

                    <RenderLocationAddress
                      city={unityClient?.city}
                      complement={unityClient?.complement}
                      district={unityClient?.district}
                      number={Number(unityClient?.number)}
                      postalCode={unityClient?.postalCode}
                      street={unityClient?.street}
                      uf={unityClient?.uf}
                    />
                  </ContentBox>
                </S.Wrapper>
              ))}

            <S.Wrapper className="service">
              <p>Serviço</p>

              {selectedServiceOrder.services.map((currentService) => (
                <ContentBox key={currentService.idBudgetService}>
                  <S.ContentBoxTitle style={{ marginBottom: "8px" }}>
                    {currentService.service.service.name}
                  </S.ContentBoxTitle>

                  {currentService.status === "concluded" && (
                    <>
                      <S.CheckIconImage src={CheckSvg} alt="check" />

                      <S.Status>
                        <div className="status"></div>
                        Concluído
                      </S.Status>
                    </>
                  )}
                  <S.ServiceInfoBox>
                    {!!currentService.service.equipment.name ? (
                      <span>{` ${currentService.service.equipment.name}`}</span>
                    ) : (
                      <span>Sem equipamento cadastrado.</span>
                    )}

                    {!!currentService.service?.equipmentType?.name && (
                      <span>
                        {` ${currentService.service.equipmentType.name}`}
                      </span>
                    )}

                    {!!currentService.service?.capacity?.name && (
                      <span>{` ${currentService.service.capacity.name}`}</span>
                    )}

                    {!!currentService.service?.brand && (
                      <span>{` | Marca: ${currentService.service.brand}`}</span>
                    )}

                    {!!currentService.service?.runtime?.time && (
                      <span>
                        {` | Execução:
                        ${currentService.service?.runtime?.time}`}
                      </span>
                    )}

                    {!!currentService.service?.runtime?.time &&
                      !!currentService.service?.runtime?.extension && (
                        <span>
                          {` ${currentService.service?.runtime?.extension}`}
                        </span>
                      )}

                    {!!currentService.service?.warranty?.time && (
                      <span>
                        {` Garantia: ${currentService.service.warranty.time}
                        ${currentService.service.warranty.extension}`}
                      </span>
                    )}

                    {!!currentService?.service?.description && (
                      <>
                        <br />
                        <S.ObservationButton
                          type="button"
                          onClick={() =>
                            handleToggle(currentService?.service?.description!)
                          }
                        >
                          Detalhamento do Serviço
                        </S.ObservationButton>
                      </>
                    )}

                    <br />
                    <S.ObservationButton
                      type="button"
                      onClick={() =>
                        handleToggleModalCheckListDetail({
                          nameCheckList:
                            currentService?.service?.serviceChecklist![0]?.name,
                          checklistRegisters:
                            currentService?.service?.serviceChecklist![0]
                              ?.registers,
                        })
                      }
                    >
                      Detalhamento dos Registros
                    </S.ObservationButton>

                    {!!currentService.userExecution?.name && (
                      <UserExecutionCard
                        userExecution={currentService.userExecution}
                      />
                    )}
                  </S.ServiceInfoBox>
                </ContentBox>
              ))}
            </S.Wrapper>

            {selectedServiceOrder.localChecklists.length > 0 && (
              <S.Wrapper>
                <p>Itens adicionais</p>

                <ContentBox>
                  {selectedServiceOrder.localChecklists.map(
                    (currentChecklist) => {
                      const { checklist, type } = currentChecklist;

                      const status = checklist?.status.find(
                        (sts) => sts.type === type
                      );

                      return (
                        <S.ContentBoxText key={currentChecklist.checklist.id}>
                          {currentChecklist.checklist.name}: {status?.label}
                        </S.ContentBoxText>
                      );
                    }
                  )}
                </ContentBox>
              </S.Wrapper>
            )}

            {selectedServiceOrder.materials.length > 0 && (
              <S.Wrapper>
                <p>Materiais</p>

                <ContentBox>
                  {selectedServiceOrder.materials.map((currentMaterial) => (
                    <S.ContentBoxText key={currentMaterial.material.id}>
                      {currentMaterial.material.item.name} (
                      {currentMaterial.quantity})
                    </S.ContentBoxText>
                  ))}
                </ContentBox>
              </S.Wrapper>
            )}

            {!!selectedServiceOrder.observations && (
              <S.Wrapper>
                <ContentBox title="Observação">
                  <S.ContentBoxObservationText>
                    {selectedServiceOrder.observations}
                  </S.ContentBoxObservationText>
                </ContentBox>
              </S.Wrapper>
            )}

            {!!canSchedule && (
              <ShowWhenHavePermission moduleHash="schedule" actionHash="create">
                <S.Wrapper>
                  <Button
                    onClick={() => {
                      handleRedirect();
                    }}
                  >
                    Agendar ordem de serviço
                  </Button>
                </S.Wrapper>
              </ShowWhenHavePermission>
            )}
            <S.WrapperArchiveButton>
              <Button typeButton="outline" onClick={handleStartServiceOrder}>
                Executar checklist
              </Button>
            </S.WrapperArchiveButton>

            <S.Wrapper>
              <ButtonSVG
                title="Download PDF"
                typeSvg="pdf"
                onClick={handleOpenPdfPage}
              />
            </S.Wrapper>

            <S.WrapperArchiveButton>
              <ShowWhenHavePermission
                moduleHash="budgets-service-order"
                actionHash="edit-archive"
              >
                <Button
                  typeButton="textOnly"
                  onClick={handleArchiveServiceOrder}
                >
                  Arquivar ordem de serviço
                </Button>
              </ShowWhenHavePermission>
            </S.WrapperArchiveButton>
          </S.Content>
        </ContentLeft>
      )}
    </S.Container>
  );
}
