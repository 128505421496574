/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint import/order: 0 */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { format } from "date-fns";

import { ContentLeft } from "templates/ContentLeft";

import { ArrowButton } from "components/ArrowButton";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { CommentsTextArea } from "components/CommentsTextArea";
import { LoadingProfiz } from "components/LoadingProfiz";

import { useAuth } from "hooks/auth";
import { useClient } from "hooks/budget/client";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { useSelectedMaterial } from "hooks/budget/selectedMaterials";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";
import { useTotalBudget } from "hooks/budget/totalBudget";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";
import { useObservation } from "hooks/budget/observation";
import { useToast } from "hooks/toast";

import { mappedServicesFromApi } from "utils/mappedServicesFromApi";
import { mappedLocalChecklistFromApi } from "utils/mappedLocalChecklistFromApi";
import { mappedMaterialsFromApi } from "utils/mappedMaterialsFromApi";
import addMaskMoney from "utils/addMaskMoney";

import { ClientArea } from "../EditBudget/ClientArea";
import { ServiceArea } from "../EditBudget/ServiceArea";
import { LocalChecklistArea } from "../EditBudget/LocalChecklistArea";
import { MaterialsArea } from "../EditBudget/MaterialsArea";
import { PaymentConditionsArea } from "../EditBudget/PaymentConditionsArea";

import * as S from "./styles";
import apiv2 from "services/apiv2";
import theme from "styles/theme";
import { ClientProps, UnityDTO } from "dtos/ClientDTO";
import { api } from "services/api";
import { UnityAreaComponent } from "components/UnityAreaComponent";
import { ContentBox } from "components/ContentBox";
import { mappedBudgetProductToUseInApp } from "utils/mappedBudgetProducts";
import { useSelectedProducts } from "hooks/budget/selectedProducts";
import { ModeProps } from "dtos/businessProposalsDTO";
import { ProductsArea } from "components/ModalProducts/ProductsArea";

type RouteParams = {
  id: string;
};

type Errors = {
  [key: string]: string;
};

type UnitiesResponse = {
  unities: UnityDTO[];
};

export function ArchivedBudget() {
  const { id } = useParams<RouteParams>();

  const { addToast } = useToast();
  const history = useHistory();

  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [formattedCreationDate, setFormattedCreationDate] = useState("");

  const [idSequence, setIdSequence] = useState(null);
  const {
    handleSetClient,
    clearClientData,
    handleSetUnityClient,
    clearUnityData,
    unity,
  } = useClient();
  const {
    selectedProductsInContext,
    handleSetSelectedProducts,
    clearSelectedProducts,
  } = useSelectedProducts();
  const { handleSetSelectedServices, clearServicesData } = useSelectedService();
  const { handleSetSelectedChecklist, clearChecklistsData } =
    useSelectedChecklist();
  const { handleSetSelectedMaterials, clearMaterialsData } =
    useSelectedMaterial();
  const { handleSetMaterialsForEditingBudget } = useMaterialsForEditingBudget();
  const { totalBudget } = useTotalBudget();
  const {
    handleSetSelectedPromptPayment,
    handleSetSelectedInstallmentPayment,
    handleSetSelectedIncomingPayment,
  } = useSelectedPaymentConditions();
  const { text, handleSetText, clearObservationData } = useObservation();

  const [mode, setMode] = useState<ModeProps | "">("");
  const [budgetValidation, setBudgetValidation] = useState("");

  const [addressNotExists, setAddressNotExists] = useState(false);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (clientResponse.address?.id?.toString() === "0") {
        return setAddressNotExists(true);
      }

      if (clientResponse?.address && clientResponse?.address?.id) {
        handleSetClient(clientResponse);
        clientResponse.address &&
          handleSetUnityClient(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      if (response.data.unities.length < 1 || !defaultUnity) {
        setAddressNotExists(true);
        return;
      }

      defaultUnity && handleSetUnityClient(defaultUnity);
    } catch {
      addToast({
        title: "Opss",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }

  async function getBudgetById(budgetId: string) {
    try {
      const response = await apiv2.get(`/budgets/${budgetId}`);
      const {
        sequenceNumber,
        created_at,
        client: clientResponse,
        mode,
        products,
        services,
        localChecklists,
        materials,
        total,
        payment_condition,
        budget_validity,
        observations,
      } = await response.data;

      setIdSequence(sequenceNumber);

      setMode(mode);
      const formattedDate = format(new Date(created_at), "dd/MM/yyyy");
      setFormattedCreationDate(formattedDate);

      handleSetClient(clientResponse);
      await loadUnityData(clientResponse);

      const mappedProducts = mappedBudgetProductToUseInApp(products);
      handleSetSelectedProducts(mappedProducts);

      const mappedServices = mappedServicesFromApi(services);
      handleSetSelectedServices(mappedServices);

      const mappedLocalChecklists =
        mappedLocalChecklistFromApi(localChecklists);
      handleSetSelectedChecklist(mappedLocalChecklists);

      const mappedMaterials = mappedMaterialsFromApi(materials);
      handleSetSelectedMaterials(mappedMaterials);
      handleSetMaterialsForEditingBudget(mappedMaterials);

      handleSetSelectedPromptPayment(payment_condition.promptPayment, total);
      handleSetSelectedInstallmentPayment(payment_condition.installment, total);
      handleSetSelectedIncomingPayment(payment_condition.incoming, total);
      handleSetBudgetValidation(budget_validity);
      handleSetText(observations);
    } catch (err) {
      addToast({
        title: "Ops...",
        description: "Erro ao carregar os dados do orçamento",
        type: "error",
      });
      // sendError((err))
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getBudgetById(id);

    return () => clearAllDataBudget();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  function clearAllDataBudget() {
    clearClientData();
    clearSelectedProducts();
    clearServicesData();
    clearChecklistsData();
    clearMaterialsData();
    clearObservationData();
    clearUnityData();
  }

  function handleSetBudgetValidation(budgetValidationText: string) {
    setBudgetValidation(budgetValidationText);
  }

  async function handleDearchiveBudget() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/${id}/dearchive`, {
        originUpdate: "web",
      });

      addToast({
        title: "Orçamento desarquivado com sucesso.",
        description: "Você será redirecionado para a listagem de orçamentos.",
        type: "success",
      });

      history.push(`/budgets/${id}`);
    } catch (error) {
      addToast({
        title: "Opss",
        description: "Não foi possível desarquivar o orçamento.",
        type: "error",
      });

      // sendError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.Container>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <ContentLeft>
          <header>
            <div>
              <ArrowButton />
            </div>

            <S.TitleHeader>Resumo do orçamento</S.TitleHeader>
          </header>

          <S.WrapperArchivedText>
            <p>Orçamento arquivado</p>
          </S.WrapperArchivedText>

          <S.Wrapper>
            <S.InfoWrapper>
              <S.TextGreen>{idSequence}</S.TextGreen>
              <S.TextGreen>{formattedCreationDate}</S.TextGreen>
            </S.InfoWrapper>

            <ClientArea disabled={true} />

            <S.Wrapper>
              {addressNotExists ? (
                <ContentBox title="Endereço">
                  <S.ContentBoxText>Sem endereço cadastrado.</S.ContentBoxText>
                </ContentBox>
              ) : (
                <UnityAreaComponent disabled={true} />
              )}
            </S.Wrapper>

            {(mode === "products" || mode === "products-services") && (
              <ProductsArea hasIdBudget={!!id} disabled={true} />
            )}

            {(mode === "services" || mode === "products-services") && (
              <ServiceArea disabled={true} />
            )}

            <LocalChecklistArea disabled={true} />

            <MaterialsArea disabled={true} />

            <S.Wrapper>
              <S.Title>Informações de pagamento</S.Title>

              <S.Wrapper>
                <Input
                  style={{
                    color: theme.colors.text_light,
                  }}
                  name="Total*"
                  value={addMaskMoney(totalBudget)}
                  readOnly
                  placeholder="R$10,00"
                />
              </S.Wrapper>

              <PaymentConditionsArea disabled={true} />

              <S.WrapperBudgetValidity>
                <S.WrapperInputBudgetValidity>
                  <Input
                    style={{
                      color: theme.colors.text_light,
                    }}
                    name="Validade do orçamento*"
                    placeholder="Ex: 7"
                    type="number"
                    value={budgetValidation}
                  />
                </S.WrapperInputBudgetValidity>

                <span>dias</span>
              </S.WrapperBudgetValidity>

              <S.Wrapper>
                <CommentsTextArea
                  style={{
                    color: theme.colors.text_light,
                  }}
                  label="Se desejar, adicione observações extras. (opcional)"
                  placeholder="Adicione observações sobre o serviço"
                  value={text}
                />
              </S.Wrapper>

              <S.WrapperButton>
                <Button typeButton="textOnly" onClick={handleDearchiveBudget}>
                  Desarquivar orçamento
                </Button>
              </S.WrapperButton>
            </S.Wrapper>
          </S.Wrapper>
        </ContentLeft>
      )}
    </S.Container>
  );
}
